
  

export const firebaseConfig = {
    //variables
    apiKey: "AIzaSyA48NB46LoT6D2NZPaspgoJaWmRJgJ5AtE",
  authDomain: "clientes-reviews.firebaseapp.com",
  projectId: "clientes-reviews",
  storageBucket: "clientes-reviews.appspot.com",
  messagingSenderId: "126630305226",
  appId: "1:126630305226:web:add924ce7e530b66884333"
  };
  